export class Contact {
    email = ''
    phone = ''
    landline = ''
    countryIso = ''
    createdAt = ''
    name = ''
    surname = ''
    // eslint-disable-next-line camelcase
    is_anonymous = 0
}
