export class BlacklistType {
  static SMS_ID = 1
  static MAILING_ID = 2
  static VOICE_ID = 3
  static RCS_ID = 9

  static SMS = 'sms'
  static MAILING = 'mailing'
  static VOICE = 'voice'
  static RCS = 'rcs'
}
